import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image,
    VStack,
    HStack,
    Badge,
    List,
    ListItem,
    ListIcon,
    useBreakpointValue,
} from '@chakra-ui/react';
import {
    FaSearch,
    FaChartLine,
    FaGlobe,
    FaCog,
    FaRocket,
    FaTools,
    FaCheck,
    FaArrowRight,
    FaMapMarkerAlt,
    FaLink,
    FaMobileAlt,
    FaPercent,
    FaSpinner,
    FaClock,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import SEOImage from '../imagesComponents/seo.jpg';
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';

const MotionBox = motion(Box);

// Statistiques
const stats = [
    { number: "98%", label: "Top Page Google" },
    { number: "+150%", label: "Trafic Moyen" },
    { number: "x3", label: "Leads Générés" }
];

// Services SEO enrichis
const features = [
    {
        id: 1,
        title: "Audit SEO Complet",
        description: "Analyse approfondie de votre site web pour identifier les opportunités d'amélioration et les obstacles techniques.",
        icon: FaSearch,
        color: "blue.400",
        details: [
            "Analyse technique",
            "Audit de contenu",
            "Étude des backlinks",
            "Rapport détaillé"
        ]
    },
    {
        id: 2,
        title: "Optimisation Technique",
        description: "Optimisation de la structure et des performances techniques de votre site pour améliorer son référencement.",
        icon: FaCog,
        color: "purple.400",
        details: [
            "Vitesse de chargement",
            "Structure HTML",
            "Responsive design",
            "Sécurité SSL"
        ]
    },
    {
        id: 3,
        title: "SEO Local",
        description: "Stratégies ciblées pour améliorer votre visibilité dans les recherches locales et attirer des clients de proximité.",
        icon: FaMapMarkerAlt,
        color: "green.400",
        details: [
            "Google My Business",
            "Citations locales",
            "Mots-clés locaux",
            "Avis clients"
        ]
    },
    {
        id: 4,
        title: "Stratégie de Liens",
        description: "Développement d'une stratégie de backlinks qualitative pour renforcer l'autorité de votre site.",
        icon: FaLink,
        color: "orange.400",
        details: [
            "Liens naturels",
            "Guest posting",
            "Relations presse",
            "Netlinking local"
        ]
    }
];

// Processus SEO
const process = [
    {
        title: "Audit Initial",
        description: "Analyse complète de votre site et de votre positionnement actuel",
        icon: FaSearch,
        duration: "1 semaine"
    },
    {
        title: "Optimisation",
        description: "Mise en place des améliorations techniques et contenus",
        icon: FaCog,
        duration: "2-3 semaines"
    },
    {
        title: "Suivi",
        description: "Monitoring des performances et ajustements",
        icon: FaChartLine,
        duration: "Continu"
    }
];

// Résultats garantis
const guarantees = [
    {
        title: "Objectifs Mesurables",
        description: "Définition d'objectifs clairs et mesurables"
    },
    {
        title: "Rapports Mensuels",
        description: "Suivi détaillé des performances"
    },
    {
        title: "Support Dédié",
        description: "Accompagnement personnalisé"
    },
    {
        title: "Transparence",
        description: "Communication claire et régulière"
    }
];

// Méthodologie SEO
const methodology = [
    "Analyse des mots-clés stratégiques",
    "Optimisation du contenu existant",
    "Création de contenu SEO",
    "Optimisation technique continue",
    "Suivi des positions",
    "Veille concurrentielle"
];

// Avantages SEO
const benefits = [
    "Augmentation du trafic qualifié",
    "Meilleure visibilité locale",
    "ROI mesurable",
    "Résultats durables",
    "Image de marque renforcée",
    "Leads qualifiés"
];

export default function ReferencementSEO() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const statBg = useColorModeValue('purple.50', 'purple.900');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    const imageWidth = useBreakpointValue({ base: '300px', md: '450px', lg: '500px' });


    return (
        <Box as="section" bg={bg} minH="100vh">
            {/* Hero Section */}
            <Container maxW="8xl" pt={{ base: 20, md: 28 }} pb={{ base: 16, md: 24 }}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16} alignItems="center">
                    <MotionBox
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <VStack align={{ base: "center", md: "start" }} spacing={8}>
                            <Badge
                                colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >
                                SEO & Référencement
                            </Badge>
                            <Heading
                                lineHeight={1.2}
                                fontWeight="bold"
                                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                                color={headingColor}
                            >
                                Boostez votre visibilité en ligne avec
                                <Flex mt={4}>
                                    <Image
                                        src={Logo}
                                        alt="Logo Rock Your Net"
                                        width={{ base: '200px', md: '280px' }}
                                    />
                                </Flex>
                            </Heading>
                            <Text color={textColor} fontSize="xl" lineHeight="tall">
                                Optimisez votre présence sur les moteurs de recherche et attirez
                                plus de clients qualifiés grâce à notre expertise en référencement naturel.
                            </Text>

                            <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>

                                <Button
                                    as={RouterLink}
                                    to="/Contact"
                                    colorScheme="purple"
                                    size="lg"
                                    rightIcon={<FaArrowRight />}
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Audit SEO Gratuit
                                </Button>
                                <Button
                                    as={RouterLink}
                                    to="/Contact"
                                    variant="outline"
                                    colorScheme="purple"
                                    size="lg"
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Nous Contacter
                                </Button>
                            </Flex>

                            {/* Stats */}
                            <SimpleGrid
                                columns={{ base: 1, sm: 3 }}
                                spacing={8}
                                w="full"
                                pt={8}
                            >
                                {stats.map((stat, index) => (
                                    <MotionBox
                                        key={index}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.2 }}
                                    >
                                        <Box
                                            textAlign="center"
                                            p={4}
                                            bg={statBg}
                                            borderRadius="lg"
                                            shadow="md"
                                        >
                                            <Text
                                                fontSize="3xl"
                                                fontWeight="bold"
                                                color="purple.500"
                                            >
                                                {stat.number}
                                            </Text>
                                            <Text fontSize="sm" color={textColor}>
                                                {stat.label}
                                            </Text>
                                        </Box>
                                    </MotionBox>
                                ))}
                            </SimpleGrid>
                        </VStack>
                    </MotionBox>

                    <MotionBox
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Image
                            src={SEOImage}
                            alt="SEO et référencement"
                            borderRadius="2xl"
                            shadow="2xl"
                            w={imageWidth}
                            maxW="600px"
                            objectFit="cover"
                        />
                    </MotionBox>
                </SimpleGrid>
            </Container>

            {/* Services SEO */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Nos Services SEO</Badge>
                            <Heading
                                fontSize={{ base: '2xl', md: '4xl' }}
                                color={headingColor}
                            >
                                Une Stratégie SEO Complète
                            </Heading>
                            <Text
                                fontSize="lg"
                                color={textColor}
                                maxW="3xl"
                            >
                                Découvrez nos services d'optimisation SEO conçus pour améliorer
                                votre classement dans les résultats de recherche.
                            </Text>
                        </VStack>

                        <SimpleGrid
                            columns={{ base: 1, md: 2, lg: 4 }}
                            spacing={8}
                            w="full"
                        >
                            {features.map((feature, index) => (
                                <MotionBox
                                    key={feature.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        bg={bg}
                                        p={8}
                                        borderRadius="xl"
                                        shadow="xl"
                                        height="full"
                                        _hover={{
                                            transform: 'translateY(-5px)',
                                            shadow: '2xl',
                                        }}
                                        transition="all 0.3s"
                                    >
                                        <VStack align="start" spacing={5}>
                                            <Box
                                                bg={feature.color}
                                                w={12}
                                                h={12}
                                                borderRadius="lg"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Icon
                                                    as={feature.icon}
                                                    color="white"
                                                    w={6}
                                                    h={6}
                                                />
                                            </Box>

                                            <Heading
                                                as="h3"
                                                fontSize="xl"
                                                color={headingColor}
                                            >
                                                {feature.title}
                                            </Heading>

                                            <Text color={textColor}>
                                                {feature.description}
                                            </Text>

                                            <List spacing={2}>
                                                {feature.details.map((detail, idx) => (
                                                    <ListItem
                                                        key={idx}
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <ListIcon
                                                            as={FaCheck}
                                                            color={feature.color}
                                                        />
                                                        <Text fontSize="sm" color={textColor}>
                                                            {detail}
                                                        </Text>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </VStack>
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Méthodologie */}
            <Container maxW="8xl" py={20}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16}>
                    <VStack align="start" spacing={8}>
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm">Notre Approche</Badge>
                        <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                            Méthodologie SEO éprouvée
                        </Heading>
                        <List spacing={4}>
                            {methodology.map((method, index) => (
                                <ListItem
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <ListIcon
                                        as={FaCheck}
                                        color="green.500"
                                        w={5}
                                        h={5}
                                    />
                                    <Text color={textColor} fontSize="lg">
                                        {method}
                                    </Text>
                                </ListItem>
                            ))}
                        </List>
                    </VStack>

                    <VStack align="start" spacing={8}>
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm">Vos Avantages</Badge>
                        <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                            Bénéfices du SEO
                        </Heading>
                        <SimpleGrid columns={2} spacing={4}>
                            {benefits.map((benefit, index) => (
                                <HStack key={index} align="start">
                                    <Icon as={FaRocket} color="orange.500" mt={1} />
                                    <Text color={textColor}>
                                        {benefit}
                                    </Text>
                                </HStack>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </SimpleGrid>
            </Container>

            {/* Processus */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Notre Processus</Badge>
                            <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                                Comment nous optimisons votre visibilité
                            </Heading>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                            {process.map((step, index) => (
                                <MotionBox
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        p={8}
                                        bg={bg}
                                        borderRadius="xl"
                                        shadow="xl"
                                        position="relative"
                                        height="full"
                                        h={{ base: 'auto', lg: '280px', xl: '270px' }}

                                    >
                                        <VStack spacing={4}>
                                            <Box
                                                bg="purple.500"
                                                color="white"
                                                borderRadius="full"
                                                w={12}
                                                h={12}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Icon as={step.icon} w={6} h={6} />
                                            </Box>
                                            <Heading
                                                as="h3"
                                                fontSize="xl"
                                                color={headingColor}
                                            >
                                                {step.title}
                                            </Heading>
                                            <Text color={textColor} textAlign="center">
                                                {step.description}
                                            </Text>
                                            <Text fontSize="sm" color="orange.500"
                                                position={{ base: 'relative', lg: 'absolute', xl: 'absolute' }}
                                                bottom={{ base: '0', lg: '30px', xl: '30px' }}>
                                                <Icon as={FaClock} mr={2} />
                                                {step.duration}
                                            </Text>
                                        </VStack>
                                        {index < process.length - 1 && (
                                            <Box
                                                display={{ base: 'none', lg: 'block' }}
                                                position="absolute"
                                                right="-60%"
                                                top="50%"
                                                w="60%"
                                                h="2px"
                                                bg="purple.500"
                                                zIndex={0}
                                            />
                                        )}
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* CTA Final */}
            <Container maxW="3xl" py={20}>
                <VStack spacing={8} textAlign="center">
                    <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                        Prêt à améliorer votre visibilité ?
                    </Heading>
                    <Text fontSize="lg" color={textColor}>
                        Commencez par un audit SEO gratuit et découvrez votre potentiel d'amélioration.
                    </Text>
                    <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                        <Button
                            as={RouterLink}
                            to="/Contact"
                            colorScheme="purple"
                            size="lg"
                            rightIcon={<FaSearch />}
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Audit SEO Gratuit
                        </Button>
                        <Button
                            as={RouterLink}
                            to="/Contact"
                            variant="outline"
                            colorScheme="purple"
                            size="lg"
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Nous Contacter
                        </Button>
                    </Flex>
                </VStack>
            </Container>
        </Box>
    );
}