import React from "react";
import {
    Box,
    Image,
    Flex,
    HStack,
    IconButton,
    useDisclosure,
    Stack,
    Link,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useTheme,
    useColorMode,
    useColorModeValue,
    useBreakpointValue,
} from "@chakra-ui/react";
import {
    HamburgerIcon,
    CloseIcon,
    MoonIcon,
    SunIcon,
    ChevronDownIcon,
} from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import LogoRYN from "../imagesComponents/Logo_RockYourNet.webp";
import Logo from "../imagesComponents/Logo_RockYourNet650.webp";

// Composant NavLink qui gère l'apparence des liens du menu
const NavLink = ({ children, href }) => {
    const theme = useTheme();
    const lineColor = useColorModeValue(
        theme.colors.light.bg,
        theme.colors.dark.bg
    );

    return (
        <Link
            className="nav-link"
            position="relative"
            px={3}
            py={2}
            rounded="md"
            as={RouterLink}
            to={href}
            _before={{
                content: '""',
                position: "absolute",
                top: "40px", // Position en dessous du texte
                left: 0,
                width: 0,
                height: "2px",
                backgroundColor: lineColor,
                transition: "width 0.4s ease",
            }}
            _hover={{
                _before: {
                    width: "100%",
                },
                textDecoration: "none",
            }}
        >
            {children}
        </Link>
    );
};

const ServicesMenu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const theme = useTheme();
    const lineColor = useColorModeValue(
        theme.colors.light.bg,
        theme.colors.dark.bg
    );
    const { colorMode } = useColorMode();

    return (
        <Menu isOpen={isOpen}>
            <MenuButton
                as={Link}
                href="/Services"
                className="nav-link"
                position="relative"
                rounded="lg"
                display="flex"
                alignItems="center"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                _hover={{
                    lg: {
                        textDecoration: "none",
                        _before: {
                            width: "100%",
                        },
                    },
                }}
                _before={{
                    lg: {
                        content: '""',
                        position: "absolute",
                        top: "32px",
                        left: 0,
                        width: 0,
                        height: "2px",
                        backgroundColor: lineColor,
                        transition: "width 0.4s ease",
                    },
                }}
                _focus={{
                    boxShadow: "none",
                    outline: "none",
                }}
            >
                <Box display="flex" alignItems="center">
                    Services
                    <ChevronDownIcon ml={2} display={{ base: "none", lg: "flex" }} />
                </Box>
            </MenuButton>

            <MenuList
                color={
                    colorMode === "dark" ? theme.colors.dark.bg : theme.colors.light.bg
                }
                bg={colorMode === "dark" ? "purple.900" : theme.colors.light.color}
                position="absolute"
                top="-4px"
                fontSize="lg"
                fontWeight="500"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                display={{ base: "none", lg: "block" }}
            >
                {/* Menu items */}
                <MenuItem
                    borderRadius="10px"
                    ml={2}
                    bg={colorMode === "dark" ? "purple.900" : theme.colors.light.color}
                    _hover={{
                        bg: colorMode === "dark" ? "purple.700" : "gray.100", // Couleur de fond selon le mode
                        color:
                            colorMode === "dark"
                                ? theme.colors.dark.bg
                                : theme.colors.light.bg, // Texte selon le mode
                    }}
                    w="93%"
                    as={RouterLink}
                    to="/Services/Site-vitrine"
                >
                    Site Vitrine
                </MenuItem>
                {/* <MenuItem
                    borderRadius="10px"
                    ml={2}
                    bg={colorMode === "dark" ? "purple.900" : theme.colors.light.color}
                    _hover={{
                        bg: colorMode === "dark" ? "purple.700" : "gray.100", // Couleur de fond selon le mode
                        color:
                            colorMode === "dark"
                                ? theme.colors.dark.bg
                                : theme.colors.light.bg, // Texte selon le mode
                    }}
                    w="93%"
                    as={RouterLink}
                    to="/Services/Site-catalogue"
                >
                    Site Catalogue
                </MenuItem> */}
                <MenuItem
                    borderRadius="10px"
                    ml={2}
                    bg={colorMode === "dark" ? "purple.900" : theme.colors.light.color}
                    _hover={{
                        bg: colorMode === "dark" ? "purple.700" : "gray.100", // Couleur de fond selon le mode
                        color:
                            colorMode === "dark"
                                ? theme.colors.dark.bg
                                : theme.colors.light.bg, // Texte selon le mode
                    }}
                    w="93%"
                    as={RouterLink}
                    to="/Services/Site-e-commerce"
                >
                    Site E-commerce
                </MenuItem>
                <MenuItem
                    borderRadius="10px"
                    ml={2}
                    bg={colorMode === "dark" ? "purple.900" : theme.colors.light.color}
                    _hover={{
                        bg: colorMode === "dark" ? "purple.700" : "gray.100", // Couleur de fond selon le mode
                        color:
                            colorMode === "dark"
                                ? theme.colors.dark.bg
                                : theme.colors.light.bg, // Texte selon le mode
                    }}
                    w="93%"
                    as={RouterLink}
                    to="/Services/Referencement"
                >
                    Référencement
                </MenuItem>
                <MenuItem
                    borderRadius="10px"
                    ml={2}
                    bg={colorMode === "dark" ? "purple.900" : theme.colors.light.color}
                    _hover={{
                        bg: colorMode === "dark" ? "purple.700" : "gray.100", // Couleur de fond selon le mode
                        color:
                            colorMode === "dark"
                                ? theme.colors.dark.bg
                                : theme.colors.light.bg, // Texte selon le mode
                    }}
                    w="93%"
                    as={RouterLink}
                    to="/Services/ApplicationWeb"
                >
                    Application Web
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

const Navbar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();
    const theme = useTheme();
    const bg = useColorModeValue('white', 'purple.900');

    // Utilisation de useBreakpointValue pour choisir le logo en fonction de la taille de l'écran
    const logoSrc = useBreakpointValue({ base: LogoRYN, md: Logo });

    return (
        <Box
            id="Navbar"
            bg={bg}
            w="100vw"
            px={4}
            borderBottom="1px"
            borderBottomColor={colorMode === "dark" ? 'gray.600' : 'gray.100'}
        >
            <Flex alignItems="center" justifyContent="space-between" py={{ base: 3, md: 4 }}>
                <Link as={RouterLink} to="/" style={{ textDecoration: "none" }}>
                    <Box
                        fontWeight="bold"
                        fontSize="xl"
                        display="flex"
                        alignItems="center"
                    >
                        <Image
                            src={logoSrc}
                            alt="Logo Rock Your Net"
                            width={{ base: "90px", md: "140px" }}
                            ml={3}
                        />
                    </Box>
                </Link>

                <HStack
                    as="nav"
                    spacing={5}
                    fontSize="xl"
                    justifyContent="center"
                    alignItems="center"
                    flex={1}
                    display={{ base: "none", lg: "flex" }}
                    color={
                        colorMode === "dark" ? theme.colors.dark.bg : theme.colors.light.bg
                    }
                >
                    <NavLink href="/">Accueil</NavLink>
                    <ServicesMenu /> {/* Insertion du menu déroulant Services */}
                    <NavLink href="/Produits">Produits</NavLink>
                    <NavLink href="/Créations">Créations</NavLink>
                    <NavLink href="/A-propos">À propos</NavLink>
                    <NavLink href="/Contact">Contact</NavLink>
                </HStack>

                <Flex alignItems="center">
                    <Button
                        onClick={toggleColorMode}
                        mr={4}
                        aria-label={colorMode === "light" ? "Activer le mode sombre" : "Activer le mode clair"}
                    >
                        {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                    </Button>

                    <IconButton
                        size="md"
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label="Open Menu"
                        display={{ lg: "none" }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                </Flex>
            </Flex>

            {isOpen ? (
                <Box py={4} display={{ lg: "none" }}>
                    <Stack
                        as="nav"
                        fontSize="xl"
                        spacing={6}
                        pl={4}
                        color={
                            colorMode === "dark"
                                ? theme.colors.dark.bg
                                : theme.colors.light.bg
                        }
                    >
                        <Link _hover={{ textDecoration: "none" }} href="/">
                            Accueil
                        </Link>
                        <ServicesMenu />
                        <Link _hover={{ textDecoration: "none" }} href="/Produits">
                            Produits
                        </Link>
                        <Link _hover={{ textDecoration: "none" }} href="/Créations">
                            Créations
                        </Link>
                        <Link _hover={{ textDecoration: "none" }} href="/A-propos">
                            À propos
                        </Link>
                        <Link _hover={{ textDecoration: "none" }} href="/Contact">
                            Contact
                        </Link>
                    </Stack>
                </Box>
            ) : null}
        </Box>
    );
};

export default Navbar;
