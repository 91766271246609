import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    Image,
    Flex,
    Button,
    Grid,
    GridItem,
    SimpleGrid,
    VStack,
    HStack,
    Icon,
    Badge,
    List,
    ListItem,
    ListIcon,
    useColorModeValue,
    useBreakpointValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
    FaCheck,
    FaRocket,
    FaLaptopCode,
    FaSearchPlus,
    FaShoppingCart,
    FaMobileAlt,
    FaArrowRight,
    FaStar
} from 'react-icons/fa';

// Import des images
import Vitrine from '../imagesComponents/vitrine.webp';
import Ecommerce from '../imagesComponents/e-commerce.webp';
import SEO from '../imagesComponents/seo.jpg';
import Catalogue from '../imagesComponents/catalogue.webp';
import ApplicationWeb from '../imagesComponents/applicationWeb.webp';

// Services enrichis avec plus d'informations
const services = [
    {
        title: "Création de sites vitrines",
        description: "Nous concevons des sites vitrines modernes et attrayants qui captent l'attention de vos visiteurs. Grâce à un design personnalisé et une ergonomie soignée, votre site sera optimisé pour représenter votre activité de manière professionnelle.",
        features: [
            "Design responsive et moderne",
            "Optimisation SEO incluse",
            "Interface d'administration intuitive",
            "Hébergement haute performance"
        ],
        benefits: [
            "Augmentez votre visibilité en ligne",
            "Présentez vos services 24/7",
            "Générez plus de leads qualifiés"
        ],
        link: "/Services/Site-vitrine",
        image: Vitrine,
        icon: FaLaptopCode,
        badge: "Best-seller"
    },
    {
        title: "Boutiques en ligne",
        description: "Avec nos solutions de sites e-commerce, nous vous aidons à vendre vos produits en ligne avec simplicité et efficacité. Nos boutiques en ligne sont pensées pour maximiser l'expérience utilisateur et augmenter vos conversions.",
        features: [
            "Paiement sécurisé multi-devises",
            "Gestion des stocks en temps réel",
            "Tunnel d'achat optimisé",
            "Dashboard analytics complet"
        ],
        benefits: [
            "Vendez 24/7 sans interruption",
            "Augmentez votre zone de chalandise",
            "Automatisez vos ventes"
        ],
        link: "/Services/Site-e-commerce",
        image: Ecommerce,
        icon: FaShoppingCart,
        badge: "Premium"
    },
    {
        title: "Référencement SEO",
        description: "Un bon référencement SEO est essentiel pour assurer la visibilité de votre site sur les moteurs de recherche. Nous mettons en place des stratégies SEO efficaces pour améliorer votre positionnement sur Google.",
        features: [
            "Audit SEO complet",
            "Optimisation technique",
            "Stratégie de contenu",
            "Suivi des performances"
        ],
        benefits: [
            "Augmentez votre trafic organique",
            "Ciblez les bons mots-clés",
            "Surpassez vos concurrents"
        ],
        link: "/Services/Referencement",
        image: SEO,
        icon: FaSearchPlus,
        badge: "Expertise"
    },
    {
        title: "Création d'applications Web",
        description: "Nous développons des applications web et mobiles sur mesure, adaptées aux besoins spécifiques de votre entreprise. Que vous souhaitiez une application performante pour gérer vos processus internes ou une solution mobile.",
        features: [
            "Développement sur mesure",
            "Architecture évolutive",
            "API REST sécurisée",
            "Progressive Web App"
        ],
        benefits: [
            "Optimisez vos processus",
            "Améliorez la productivité",
            "Innovation technologique"
        ],
        link: "/Services/ApplicationWeb",
        image: ApplicationWeb,
        icon: FaMobileAlt,
        badge: "Innovation"
    }
];

export default function ServicesPage() {
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const bg = useColorModeValue('gray.50', 'gray.900');
    const accentColor = useColorModeValue('purple.700', 'orange.200');
    const badgeBg = useColorModeValue('orange.200', 'purple.900');

    const imageWidth = useBreakpointValue({ base: '300px', md: '450px', lg: '500px' });


    return (
        <Box as="section" py={12} bg={bg} minH="100vh">
            {/* Hero Section */}
            <Container maxW="7xl" textAlign="center" mb={16}>
                <Badge
                    colorScheme="purple"
                    fontSize="md"
                    px={6}
                    py={1}
                    borderRadius="full"
                    mb={6}
                >
                    Nos Solutions
                </Badge>
                <Heading
                    as="h1"
                    fontSize={{ base: '3xl', md: '5xl' }}
                    fontWeight="bold"
                    color={headingColor}
                    mb={6}
                >
                    Des Services Web Sur Mesure
                </Heading>
                <Text
                    fontSize={{ base: 'lg', md: 'xl' }}
                    color={textColor}
                    maxW="3xl"
                    mx="auto"
                >
                    Découvrez nos services conçus pour développer votre présence en ligne et optimiser votre impact digital.
                    De la conception à la mise en ligne, nous vous accompagnons dans la réussite de vos projets.
                </Text>
            </Container>

            {/* Services Grid */}
            <Container maxW={{ base: "100%", xl: "9xl" }}>
                <Flex
                    display='flex'
                    justifyContent='center'
                    flexWrap="wrap"
                    gap={10}
                    w="full"
                >
                    {services.map((service, index) => (
                        <Box key={index}>
                            <Flex
                                bg={cardBg}
                                borderRadius="xl"
                                shadow="xl"
                                p={6}
                                direction="column"
                                h="full"
                                w={{ base: 'auto', md: '380px' }}
                                transition="all 0.3s"
                                _hover={{ shadow: '2xl' }}
                                position="relative"
                                overflow="hidden"
                            >
                                {service.badge && (
                                    <Badge
                                        position="absolute"
                                        top={4}
                                        right={4}
                                        bg={badgeBg}
                                        color={accentColor}
                                        px={3}
                                        py={1}
                                        borderRadius="full"
                                        fontSize="md"
                                    >
                                        {service.badge}
                                    </Badge>
                                )}

                                {/* Image Section */}
                                <Box mb={6}>
                                    <Image
                                        src={service.image}
                                        alt={service.title}
                                        borderRadius="lg"
                                        w="full"
                                        h="250px"
                                        objectFit="cover"
                                        loading="lazy"
                                    />
                                </Box>

                                {/* Content Section */}
                                <VStack align="stretch" flex="1" spacing={6}>
                                    <HStack>
                                        <Icon as={service.icon} w={6} h={6} color={accentColor} />
                                        <Heading as="h3" fontSize="2xl" color={headingColor}>
                                            {service.title}
                                        </Heading>
                                    </HStack>

                                    <Text color={textColor} fontSize="lg">
                                        {service.description}
                                    </Text>

                                    <Box>
                                        <Text fontSize="md" fontWeight="semibold" color={headingColor} mb={2}>
                                            Fonctionnalités incluses :
                                        </Text>
                                        <List spacing={2}>
                                            {service.features.map((feature, idx) => (
                                                <ListItem key={idx}>
                                                    <ListIcon as={FaCheck} color="green.500" />
                                                    <Text as="span" color={textColor}>
                                                        {feature}
                                                    </Text>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>

                                    <Box>
                                        <Text fontSize="md" fontWeight="semibold" color={headingColor} mb={2}>
                                            Avantages :
                                        </Text>
                                        <List spacing={2}>
                                            {service.benefits.map((benefit, idx) => (
                                                <ListItem key={idx}>
                                                    <ListIcon as={FaStar} color="yellow.500" />
                                                    <Text as="span" color={textColor}>
                                                        {benefit}
                                                    </Text>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Box>

                                    <Button
                                        as={RouterLink}
                                        to={service.link}
                                        colorScheme="purple"
                                        size="lg"
                                        rightIcon={<FaArrowRight />}
                                        mt="auto"
                                        _hover={{
                                            bg: 'white',            // Couleur de fond blanche au survol
                                            color: 'purple.600',     // Texte de couleur purple.600 au survol
                                            border: '1px solid',     // Ajoute une bordure
                                            borderColor: 'purple.600',
                                            transform: 'translateY(-2px)',
                                            shadow: 'xl' // Couleur de la bordure purple.600
                                        }}
                                    >
                                        En savoir plus
                                    </Button>
                                </VStack>
                            </Flex>
                        </Box>
                    ))}
                </Flex>
            </Container>

            {/* CTA Section */}
            <Container maxW="7xl" textAlign="center" mt={32} mb={20}>
                <VStack spacing={8}>
                    <Icon as={FaRocket} w={10} h={10} color={accentColor} />
                    <Heading as="h3" fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                        Prêt à Transformer Votre Présence en Ligne ?
                    </Heading>
                    <Text fontSize="lg" color={textColor} maxW="2xl" mx="auto">
                        Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir
                        comment nous pouvons vous aider à atteindre vos objectifs digitaux.
                    </Text>
                    <Button
                        as={RouterLink}
                        to="/Contact"
                        size="lg"
                        colorScheme="purple"
                        px={8}
                        py={6}
                        fontSize="lg"
                        rightIcon={<FaArrowRight />}
                        _hover={{
                            bg: 'white',            // Couleur de fond blanche au survol
                            color: 'purple.600',     // Texte de couleur purple.600 au survol
                            border: '1px solid',     // Ajoute une bordure
                            borderColor: 'purple.600',
                            transform: 'translateY(-2px)',
                            shadow: 'xl' // Couleur de la bordure purple.600
                        }}
                    >
                        Démarrer Votre Projet
                    </Button>
                </VStack>
            </Container>
        </Box>
    );
}