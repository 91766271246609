import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image,
    VStack,
    HStack,
    Badge,
    List,
    ListItem,
    ListIcon,
    Divider,
    useBreakpointValue,
} from '@chakra-ui/react';
import {
    FaCheck,
    FaMobileAlt,
    FaSearch,
    FaCog,
    FaClock,
    FaRocket,
    FaPaintBrush,
    FaChartLine,
    FaShieldAlt,
    FaTools,
    FaArrowRight,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import VitrineImage from '../imagesComponents/vitrine.webp';
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';

const MotionBox = motion(Box);

// Fonctionnalités enrichies
const features = [
    {
        id: 1,
        title: "Design Moderne",
        description: "Nous concevons des sites vitrines élégants et modernes qui captent l'attention de vos visiteurs et reflètent l'identité de votre entreprise.",
        icon: FaPaintBrush,
        color: "blue.400",
        details: [
            "Interface utilisateur intuitive",
            "Design personnalisé",
            "Expérience utilisateur optimisée",
            "Animations modernes"
        ]
    },
    {
        id: 2,
        title: "100% Responsive",
        description: "Votre site s'adapte parfaitement à tous les appareils pour une expérience utilisateur optimale sur smartphones, tablettes et ordinateurs.",
        icon: FaMobileAlt,
        color: "purple.400",
        details: [
            "Adaptation automatique",
            "Navigation fluide",
            "Temps de chargement optimisé",
            "Tests multi-appareils"
        ]
    },
    {
        id: 3,
        title: "SEO Optimisé",
        description: "Maximisez votre visibilité en ligne grâce à une optimisation SEO complète qui améliore votre classement dans les résultats de recherche.",
        icon: FaSearch,
        color: "green.400",
        details: [
            "Structure optimisée",
            "Méta-données personnalisées",
            "Balisage schema.org",
            "Performance optimale"
        ]
    },
    {
        id: 4,
        title: "Maintenance Pro",
        description: "Bénéficiez d'un suivi régulier et d'une maintenance professionnelle pour garantir la performance et la sécurité de votre site.",
        icon: FaTools,
        color: "orange.400",
        details: [
            "Mises à jour régulières",
            "Sauvegardes automatiques",
            "Support technique",
            "Monitoring 24/7"
        ]
    }
];

// Processus de création
const process = [
    {
        title: "Analyse & Conception",
        description: "Étude approfondie de vos besoins et création d'une maquette personnalisée",
        icon: FaCog,
        duration: "1-2 semaines"
    },
    {
        title: "Développement",
        description: "Création de votre site avec les dernières technologies web",
        icon: FaRocket,
        duration: "2-4 semaines"
    },
    {
        title: "Optimisation",
        description: "Tests approfondis et optimisation des performances",
        icon: FaChartLine,
        duration: "1 semaine"
    },
    {
        title: "Mise en ligne",
        description: "Déploiement sécurisé et formation à l'utilisation",
        icon: FaShieldAlt,
        duration: "1 semaine"
    }
];

// Avantages clés
const benefits = [
    "Vitrine professionnelle 24/7",
    "Augmentation de la visibilité en ligne",
    "Génération de leads qualifiés",
    "Amélioration de la crédibilité",
    "Réduction des coûts marketing",
    "Support client réactif"
];

// Garanties
const guarantees = [
    "Satisfaction client garantie",
    "Support technique inclus",
    "Hébergement haute performance",
    "Sécurité renforcée"
];

export default function SiteVitrine() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const statBg = useColorModeValue('purple.50', 'purple.900');

    const imageWidth = useBreakpointValue({ base: '300px', md: '450px', lg: '500px' });

    return (
        <Box as="section" bg={bg} minH="100vh">
            {/* Hero section améliorée */}
            <Container maxW="8xl" pt={{ base: 20, md: 28 }} pb={{ base: 16, md: 24 }}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16} alignItems="center">
                    <MotionBox
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <VStack align={{ base: 'center', md: 'start' }} spacing={8}>
                            <Badge
                                colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >
                                Site Vitrine Professionnel
                            </Badge>
                            <Heading
                                lineHeight={1.2}
                                fontWeight="bold"
                                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                                color={headingColor}
                            >
                                Créez votre présence en ligne avec
                                <Flex mt={4}>
                                    <Image
                                        src={Logo}
                                        alt="Logo Rock Your Net"
                                        width={{ base: '200px', md: '280px' }}
                                    />
                                </Flex>
                            </Heading>
                            <Text color={textColor} fontSize="xl" lineHeight="tall">
                                Transformez votre vision en une vitrine digitale impressionnante.
                                Nous créons des sites web professionnels qui captent l'attention,
                                convertissent les visiteurs et renforcent votre marque.
                            </Text>
                            <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>

                                <Button
                                    as={RouterLink}
                                    to="/Contact"
                                    colorScheme="purple"
                                    size="lg"
                                    rightIcon={<FaArrowRight />}
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Démarrer Votre Projet
                                </Button>
                                <Button
                                    as={RouterLink}
                                    to="/Créations"
                                    variant="outline"
                                    colorScheme="purple"
                                    size="lg"
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Voir Nos Réalisations
                                </Button>
                            </Flex>

                            {/* Stats rapides */}
                            <SimpleGrid columns={2} spacing={8} pt={8}>
                                <Box textAlign="center" p={4} bg={statBg} borderRadius="lg">
                                    <Text fontSize="3xl" fontWeight="bold" color="purple.500">100%</Text>
                                    <Text fontSize="sm" color={textColor}>Clients Satisfaits</Text>
                                </Box>
                                <Box textAlign="center" p={4} bg={statBg} borderRadius="lg">
                                    <Text fontSize="3xl" fontWeight="bold" color="purple.500">24/7</Text>
                                    <Text fontSize="sm" color={textColor}>Support</Text>
                                </Box>
                            </SimpleGrid>
                        </VStack>
                    </MotionBox>

                    <MotionBox
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Image
                            src={VitrineImage}
                            alt="Site vitrine exemple"
                            width={imageWidth}
                            objectFit="cover"
                            borderRadius="2xl"
                            shadow="2xl"
                        />
                    </MotionBox>
                </SimpleGrid>
            </Container>

            {/* Section des fonctionnalités */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Fonctionnalités</Badge>
                            <Heading
                                fontSize={{ base: '2xl', md: '4xl' }}
                                color={headingColor}
                            >
                                Tout ce qu'il faut pour réussir en ligne
                            </Heading>
                            <Text
                                fontSize="lg"
                                color={textColor}
                                maxW="3xl"
                            >
                                Découvrez nos fonctionnalités premium conçues pour maximiser
                                votre impact en ligne et convertir vos visiteurs en clients.
                            </Text>
                        </VStack>

                        <SimpleGrid
                            columns={{ base: 1, md: 2, lg: 4 }}
                            spacing={8}
                            w="full"
                        >
                            {features.map((feature, index) => (
                                <MotionBox
                                    key={feature.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        bg={bg}
                                        p={8}
                                        borderRadius="xl"
                                        shadow="xl"
                                        height="full"
                                        _hover={{
                                            transform: 'translateY(-5px)',
                                            shadow: '2xl',
                                        }}
                                        transition="all 0.3s"
                                    >
                                        <VStack align="start" spacing={5}>
                                            <Box
                                                bg={feature.color}
                                                w={12}
                                                h={12}
                                                borderRadius="lg"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Icon
                                                    as={feature.icon}
                                                    color="white"
                                                    w={6}
                                                    h={6}
                                                />
                                            </Box>

                                            <Heading
                                                as="h3"
                                                fontSize="xl"
                                                color={headingColor}
                                            >
                                                {feature.title}
                                            </Heading>

                                            <Text color={textColor}>
                                                {feature.description}
                                            </Text>

                                            <List spacing={2}>
                                                {feature.details.map((detail, idx) => (
                                                    <ListItem
                                                        key={idx}
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <ListIcon
                                                            as={FaCheck}
                                                            color={feature.color}
                                                        />
                                                        <Text fontSize="sm" color={textColor}>
                                                            {detail}
                                                        </Text>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </VStack>
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Processus de création */}
            <Container maxW="8xl" py={20}>
                <VStack spacing={12}>
                    <VStack spacing={4} textAlign="center">
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm">Notre Processus</Badge>
                        <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                            Comment nous créons votre site
                        </Heading>
                        <Text fontSize="lg" color={textColor} maxW="3xl">
                            Un processus éprouvé et transparent pour transformer votre vision en réalité
                        </Text>
                    </VStack>

                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                        {process.map((step, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <Box
                                    bg={cardBg}
                                    p={8}
                                    h={{ base: 'auto', lg: '350px', xl: '270px' }}
                                    borderRadius="xl"
                                    shadow="xl"
                                    position="relative"
                                >
                                    <VStack spacing={4} align="start">
                                        <Box
                                            bg="purple.500"
                                            color="white"
                                            borderRadius="full"
                                            w={10}
                                            h={10}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            fontSize="lg"
                                            fontWeight="bold"
                                        >
                                            {index + 1}
                                        </Box>
                                        <Heading
                                            as="h3"
                                            fontSize="xl"
                                            color={headingColor}
                                        >
                                            {step.title}
                                        </Heading>
                                        <Text color={textColor}>
                                            {step.description}
                                        </Text>

                                        <HStack color="orange.500"
                                            position={{ base: 'relative', lg: 'absolute', xl: 'absolute' }}
                                            bottom={{ base: '0', lg: '30px', xl: '30px' }} >
                                            <Icon as={FaClock} />
                                            <Text fontSize="sm" fontWeight="medium">
                                                {step.duration}
                                            </Text>
                                        </HStack>
                                    </VStack>
                                    {index < process.length - 1 && (
                                        <Box
                                            display={{ base: 'none', lg: 'block' }}
                                            position="absolute"
                                            right="-60%"
                                            top="50%"
                                            w="60%"
                                            h="2px"
                                            bg="purple.500"
                                            zIndex={0}
                                        />
                                    )}
                                </Box>
                            </MotionBox>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>

            {/* Avantages et Garanties */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16}>
                        {/* Avantages */}
                        <VStack align="start" spacing={8}>
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Avantages</Badge>
                            <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                                Pourquoi choisir un site vitrine ?
                            </Heading>
                            <List spacing={4}>
                                {benefits.map((benefit, index) => (
                                    <ListItem
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <ListIcon
                                            as={FaCheck}
                                            color="green.500"
                                            w={5}
                                            h={5}
                                        />
                                        <Text color={textColor} fontSize="lg">
                                            {benefit}
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </VStack>

                        {/* Garanties */}
                        <VStack align="start" spacing={8}>
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Garanties</Badge>
                            <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                                Notre engagement qualité
                            </Heading>
                            <List spacing={4}>
                                {guarantees.map((guarantee, index) => (
                                    <ListItem
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <ListIcon
                                            as={FaShieldAlt}
                                            color="orange.500"
                                            w={5}
                                            h={5}
                                        />
                                        <Text color={textColor} fontSize="lg">
                                            {guarantee}
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </VStack>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* CTA Final */}
            <Container maxW="3xl" py={20}>
                <VStack spacing={8} textAlign="center">
                    <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                        Prêt à lancer votre projet ?
                    </Heading>
                    <Text fontSize="lg" color={textColor}>
                        Transformez votre vision en réalité avec un site vitrine professionnel qui vous démarque de la concurrence.
                    </Text>
                    <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>

                        <Button
                            as={RouterLink}
                            to="/Contact"
                            colorScheme="purple"
                            size="lg"
                            rightIcon={<FaRocket />}
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Démarrer Votre Projet
                        </Button>
                        <Button
                            as={RouterLink}
                            to="/Services"
                            variant="outline"
                            colorScheme="purple"
                            size="lg"
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            En Savoir Plus
                        </Button>
                    </Flex>
                </VStack>
            </Container>
        </Box >
    );
}