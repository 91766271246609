import React from 'react';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    Image,
    Flex,
    Grid,
    VStack,
    Icon,
    Badge,
    useTheme,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaRocket, FaSearch, FaShoppingCart, FaDesktop, FaMobile } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Vitrine from '../imagesComponents/vitrine.webp';
import Ecommerce from '../imagesComponents/e-commerce.webp';
import SEO from '../imagesComponents/seo.jpg';
import Catalogue from '../imagesComponents/catalogue.webp';
import ApplicationWeb from '../imagesComponents/applicationWeb.webp';

const MotionBox = motion(Box);

const services = [
    {
        title: "Sites Vitrines",
        description: "Votre vitrine digitale professionnelle et attractive",
        longDescription: "Un site vitrine moderne, responsive et optimisé pour convertir vos visiteurs en clients. Idéal pour les PME, artisans et professions libérales.",
        image: Vitrine,
        link: "/Services/Site-vitrine",
        icon: FaDesktop,
        features: ["Design sur mesure", "100% responsive", "Optimisé SEO", "Interface administrateur"],
        badge: "Best-seller"
    },
    {
        title: "Sites E-commerce",
        description: "Vendez vos produits en ligne 24/7",
        longDescription: "Une boutique en ligne performante et sécurisée pour développer vos ventes sur internet avec une expérience d'achat optimale.",
        image: Ecommerce,
        link: "/Services/Site-e-commerce",
        icon: FaShoppingCart,
        features: ["Paiement sécurisé", "Gestion des stocks", "Multi-devises", "Analytics"]
    },
    {
        title: "Référencement SEO",
        description: "Augmentez votre visibilité sur Google",
        longDescription: "Stratégie SEO complète pour améliorer votre positionnement et attirer un trafic qualifié sur votre site web.",
        image: SEO,
        link: "/Services/Referencement",
        icon: FaSearch,
        features: ["Audit complet", "Optimisation technique", "Création de contenu", "Suivi des positions"]
    },
    {
        title: "Applications Web",
        description: "Solutions digitales sur mesure",
        longDescription: "Développement d'applications web personnalisées pour optimiser vos processus métier et améliorer votre productivité.",
        image: ApplicationWeb,
        link: "/Services/ApplicationWeb",
        icon: FaMobile,
        features: ["Progressive Web App", "API REST", "Base de données", "Temps réel"],
        badge: "Nouveau"
    }
];

const ServiceCard = ({ service }) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const cardBg = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const buttonBG = useColorModeValue('purple.500', 'purple.200');
    const buttonBgHover = useColorModeValue('white', 'gray.50');
    const buttonColor = useColorModeValue('white', 'gray.700');
    const buttonColorHover = useColorModeValue('purple.600', 'purple.600');
    const accentColor = useColorModeValue('purple.700', 'orange.100');
    const badgeBg = useColorModeValue('orange.200', 'purple.900');

    return (
        <MotionBox
            as="article"
            w="full"
            h="full"
            borderRadius="xl"
            overflow="hidden"
            bg={cardBg}
            shadow="xl"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            _hover={{ transform: 'translateY(-8px)', shadow: '2xl' }}
            display="flex"
            flexDirection="column"
        >
            <Box position="relative">
                <Image
                    src={service.image}
                    alt={service.title}
                    w="full"
                    h="full"
                    objectFit="cover"
                    objectPosition="center"
                    loading="lazy"
                />
                {service.badge && (
                    <Badge
                        position="absolute"
                        top={4}
                        right={4}
                        colorScheme="purple"
                        bg={badgeBg}
                        color={accentColor}
                        fontSize="sm"
                        px={3}
                        py={1}
                        borderRadius="full"
                    >
                        {service.badge}
                    </Badge>
                )}
            </Box>

            <VStack
                p={6}
                align="stretch"
                spacing={4}
                flex="1"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Box>
                    <Flex align="center" gap={3} mb={4}>
                        <Icon as={service.icon} w={6} h={6} color={accentColor} />
                        <Heading
                            as="h3"
                            fontSize="xl"
                            color={colorMode === "dark" ? "white" : "gray.800"}
                        >
                            {service.title}
                        </Heading>
                    </Flex>

                    <Text color={textColor} fontSize="md" mb={4}>
                        {service.longDescription}
                    </Text>

                    <Box>
                        <Text fontWeight="semibold" mb={2} color={colorMode === "dark" ? "white" : "gray.800"}>
                            Fonctionnalités incluses :
                        </Text>
                        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                            {service.features.map((feature, index) => (
                                <Flex key={index} align="center" gap={2}>
                                    <Icon as={FaRocket} color="orange.500" w={3} h={3} />
                                    <Text fontSize="sm" color={textColor}>
                                        {feature}
                                    </Text>
                                </Flex>
                            ))}
                        </Grid>
                    </Box>
                </Box>

                <Box mt="auto" pt={4}>
                    <Button
                        as={RouterLink}
                        to={service.link}
                        colorScheme="purple"
                        variant="outline"
                        size="lg"
                        w="full"
                        bg={buttonBG}
                        color={buttonColor}
                        _hover={{
                            transform: 'translateY(-2px)',
                            shadow: 'lg',
                            bg: buttonBgHover,
                            color: buttonColorHover
                        }}
                    >
                        En savoir plus
                    </Button>
                </Box>
            </VStack>
        </MotionBox>
    );
};

const ServicesSection = () => {
    const headingColor = useColorModeValue('gray.800', 'white');
    const bg = useColorModeValue('gray.100', 'gray.800');

    return (
        <Box as="section" py={{ base: '20', md: '32' }} bg={bg}>
            <Container maxW="8xl">
                <VStack spacing={16}>
                    <VStack spacing={6} textAlign="center" maxW="3xl" mx="auto">
                        <Badge colorScheme="purple" fontSize="md" px={6} py={1} borderRadius="full">
                            Nos Solutions
                        </Badge>
                        <Heading
                            as="h2"
                            fontSize={{ base: '3xl', md: '5xl' }}
                            fontWeight="bold"
                            color={headingColor}
                            lineHeight="shorter"
                        >
                            Des Services Web Sur Mesure
                        </Heading>
                        <Text fontSize={{ base: 'lg', md: 'xl' }} color={useColorModeValue('gray.600', 'gray.300')}>
                            De la conception à la mise en ligne, nous vous accompagnons dans la création
                            de votre projet digital avec des solutions adaptées à vos besoins.
                        </Text>
                    </VStack>

                    <Box w="full" display="flex" justifyContent="center">
                        <Box w="full" px={{ base: 4, md: 0 }}>
                            <Flex
                                flexWrap="wrap"
                                gap={4}
                                justify="center"
                            >
                                {services.map((service, index) => (
                                    <Box
                                        key={index}
                                        flex={{ base: "1 1 100%", md: "0 1 340px" }}
                                        maxW="400px"
                                    >
                                        <ServiceCard service={service} />
                                    </Box>
                                ))}
                            </Flex>
                        </Box>
                    </Box>

                    <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                        <Button
                            as={RouterLink}
                            to="/Contact"
                            size="lg"
                            colorScheme="purple"
                            px={8}
                            mr={4}
                            _hover={{
                                bg: 'white',            // Couleur de fond blanche au survol
                                color: 'purple.600',     // Texte de couleur purple.600 au survol
                                border: '1px solid',     // Ajoute une bordure
                                borderColor: 'purple.600',
                                transform: 'translateY(-2px)',
                                shadow: 'xl' // Couleur de la bordure purple.600
                            }}
                        >
                            Démarrer mon projet
                        </Button>
                        <Button
                            as={RouterLink}
                            to="/Services"
                            size="lg"
                            variant="outline"
                            colorScheme="purple"
                            w="238px"
                            px={8}
                            _hover={{
                                transform: 'translateY(-2px)',
                                shadow: 'xl',
                                bg: "white",            // Couleur de fond blanche au survol
                                color: 'purple.600'
                            }}
                        >
                            Tous nos services
                        </Button>
                    </Flex>
                </VStack>
            </Container>
        </Box>
    );
};

export default ServicesSection;