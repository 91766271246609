import React from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Icon,
    Stack,
    Flex,
    Button,
    useColorModeValue,
    Image,
    VStack,
    HStack,
    Badge,
    List,
    ListItem,
    ListIcon,
    useBreakpointValue,
} from '@chakra-ui/react';
import {
    FaCode,
    FaMobile,
    FaRocket,
    FaCogs,
    FaShieldAlt,
    FaDesktop,
    FaUsers,
    FaTools,
    FaChartLine,
    FaCheck,
    FaDatabase,
    FaCloud,
    FaArrowRight,
    FaClock,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import AppImage from '../imagesComponents/applicationWeb.webp';
import Logo from '../imagesComponents/Logo_RockYourNet650.webp';
import { Link as RouterLink } from 'react-router-dom';

const MotionBox = motion(Box);

// Stats
const stats = [
    { number: "100%", label: "Clients satisfaits" },
    { number: "100%", label: "Développement sécurisé" },
    { number: "24/7", label: "Support Client" }
];

// Services de développement enrichis
const features = [
    {
        id: 1,
        title: "Applications Web",
        description: "Développement d'applications web performantes et évolutives adaptées à vos besoins métier.",
        icon: FaDesktop,
        color: "blue.400",
        details: [
            "Architecture moderne",
            "Interface responsive",
            "Performance optimisée",
            "Sécurité renforcée"
        ]
    },
    {
        id: 2,
        title: "Applications Mobiles",
        description: "Création d'applications mobiles natives et hybrides pour iOS et Android.",
        icon: FaMobile,
        color: "green.400",
        details: [
            "Design natif",
            "Multi-plateformes",
            "Mode hors-ligne",
            "Push notifications"
        ]
    },
    {
        id: 3,
        title: "Solutions Cloud",
        description: "Déploiement et gestion de vos applications dans le cloud pour une scalabilité optimale.",
        icon: FaCloud,
        color: "purple.400",
        details: [
            "Infrastructure scalable",
            "Haute disponibilité",
            "Backup automatisé",
            "Monitoring 24/7"
        ]
    },
    {
        id: 4,
        title: "Maintenance & Support",
        description: "Support continu et maintenance évolutive de vos applications.",
        icon: FaTools,
        color: "orange.400",
        details: [
            "Mises à jour régulières",
            "Support technique",
            "Évolutions continues",
            "Documentation complète"
        ]
    }
];

// Technologies utilisées
const technologies = [
    {
        category: "Front-end",
        techs: ["React", "Vue.js", "Angular", "Next.js"]
    },
    {
        category: "Back-end",
        techs: ["Node.js", "Python", "PHP", "Java"]
    },
    {
        category: "Mobile",
        techs: ["React Native", "Flutter", "Swift", "Kotlin"]
    },
    {
        category: "Base de données",
        techs: ["MongoDB", "MySQL", "PostgreSQL", "Firebase"]
    }
];

// Processus de développement
const process = [
    {
        title: "Analyse des Besoins",
        description: "Étude approfondie de vos besoins et objectifs",
        icon: FaUsers,
        duration: "1-2 semaines"
    },
    {
        title: "Design & Architecture",
        description: "Conception de l'architecture et des maquettes",
        icon: FaCogs,
        duration: "2-3 semaines"
    },
    {
        title: "Développement",
        description: "Création de l'application avec des sprints réguliers",
        icon: FaCode,
        duration: "2-4 mois"
    },
    {
        title: "Tests & Déploiement",
        description: "Tests approfondis et mise en production",
        icon: FaRocket,
        duration: "2-3 semaines"
    }
];

// Garanties
const guarantees = [
    "Code source propriétaire",
    "Sécurité renforcée",
    "Performance optimisée",
    "Support technique dédié",
    "Documentation complète",
    "Formation utilisateur"
];

// Types d'applications
const appTypes = [
    {
        type: "Applications Métier",
        examples: [
            "CRM sur mesure",
            "Gestion de stock",
            "Facturation",
            "Planification"
        ]
    },
    {
        type: "Applications Grand Public",
        examples: [
            "E-commerce",
            "Réseaux sociaux",
            "Services en ligne",
            "Applications mobiles"
        ]
    },
    {
        type: "Intégrations",
        examples: [
            "APIs REST",
            "Webhooks",
            "SSO",
            "Passerelles de paiement"
        ]
    }
];

export default function ApplicationWebPage() {
    const bg = useColorModeValue('gray.50', 'gray.900');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const cardBg = useColorModeValue('white', 'gray.700');
    const statBg = useColorModeValue('purple.50', 'purple.900');
    const borderColor = useColorModeValue('gray.200', 'gray.700');

    const imageWidth = useBreakpointValue({ base: '300px', md: '450px', lg: '500px' });


    return (
        <Box as="section" bg={bg} minH="100vh">
            {/* Hero Section */}
            <Container maxW="8xl" pt={{ base: 20, md: 28 }} pb={{ base: 16, md: 24 }}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16} alignItems="center">
                    <MotionBox
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <VStack align={{ base: "center", md: "start" }} spacing={8}>
                            <Badge
                                colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm"
                            >
                                Développement d'Applications
                            </Badge>
                            <Heading
                                lineHeight={1.2}
                                fontWeight="bold"
                                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                                color={headingColor}
                            >
                                Créez votre application avec
                                <Flex mt={4}>
                                    <Image
                                        src={Logo}
                                        alt="Logo Rock Your Net"
                                        width={{ base: '200px', md: '280px' }}
                                    />
                                </Flex>
                            </Heading>
                            <Text color={textColor} fontSize="xl" lineHeight="tall">
                                Transformez vos idées en applications performantes. Notre expertise
                                en développement web et mobile vous garantit des solutions sur mesure,
                                évolutives et sécurisées.
                            </Text>

                            <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                                <Button
                                    as={RouterLink}
                                    to="/Contact"
                                    colorScheme="purple"
                                    size="lg"
                                    rightIcon={<FaArrowRight />}
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Démarrer Votre Projet
                                </Button>
                                <Button
                                    as={RouterLink}
                                    to="/Créations"
                                    variant="outline"
                                    colorScheme="purple"
                                    size="lg"
                                    _hover={{
                                        bg: 'white',            // Couleur de fond blanche au survol
                                        color: 'purple.600',     // Texte de couleur purple.600 au survol
                                        border: '1px solid',     // Ajoute une bordure
                                        borderColor: 'purple.600',
                                        transform: 'translateY(-2px)',
                                        shadow: 'xl' // Couleur de la bordure purple.600
                                    }}
                                >
                                    Voir Nos Réalisations
                                </Button>
                            </Flex>

                            {/* Stats */}
                            <SimpleGrid
                                columns={{ base: 1, sm: 3 }}
                                spacing={8}
                                w="full"
                                pt={8}
                            >
                                {stats.map((stat, index) => (
                                    <MotionBox
                                        key={index}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ delay: index * 0.2 }}
                                    >
                                        <Box
                                            textAlign="center"
                                            p={4}
                                            bg={statBg}
                                            borderRadius="lg"
                                            shadow="md"
                                        >
                                            <Text
                                                fontSize="3xl"
                                                fontWeight="bold"
                                                color="purple.500"
                                            >
                                                {stat.number}
                                            </Text>
                                            <Text fontSize="sm" color={textColor}>
                                                {stat.label}
                                            </Text>
                                        </Box>
                                    </MotionBox>
                                ))}
                            </SimpleGrid>
                        </VStack>
                    </MotionBox>

                    <MotionBox
                        initial={{ opacity: 0, x: 50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        display="flex"
                        justifyContent="center"
                    >
                        <Image
                            src={AppImage}
                            alt="Développement d'applications"
                            borderRadius="2xl"
                            shadow="2xl"
                            w={imageWidth}
                            maxW="600px"
                            objectFit="cover"
                        />
                    </MotionBox>
                </SimpleGrid>
            </Container>

            {/* Services Section */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Nos Services</Badge>
                            <Heading
                                fontSize={{ base: '2xl', md: '4xl' }}
                                color={headingColor}
                            >
                                Solutions de Développement Complètes
                            </Heading>
                            <Text
                                fontSize="lg"
                                color={textColor}
                                maxW="3xl"
                            >
                                Du développement à la maintenance, nous vous accompagnons à chaque
                                étape de votre projet digital.
                            </Text>
                        </VStack>

                        <SimpleGrid
                            columns={{ base: 1, md: 2, lg: 4 }}
                            spacing={8}
                            w="full"
                        >
                            {features.map((feature, index) => (
                                <MotionBox
                                    key={feature.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        bg={bg}
                                        p={8}
                                        borderRadius="xl"
                                        shadow="xl"
                                        height="full"
                                        _hover={{
                                            transform: 'translateY(-5px)',
                                            shadow: '2xl',
                                        }}
                                        transition="all 0.3s"
                                    >
                                        <VStack align="start" spacing={5}>
                                            <Box
                                                bg={feature.color}
                                                w={12}
                                                h={12}
                                                borderRadius="lg"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Icon
                                                    as={feature.icon}
                                                    color="white"
                                                    w={6}
                                                    h={6}
                                                />
                                            </Box>

                                            <Heading
                                                as="h3"
                                                fontSize="xl"
                                                color={headingColor}
                                            >
                                                {feature.title}
                                            </Heading>

                                            <Text color={textColor}>
                                                {feature.description}
                                            </Text>

                                            <List spacing={2}>
                                                {feature.details.map((detail, idx) => (
                                                    <ListItem
                                                        key={idx}
                                                        display="flex"
                                                        alignItems="center"
                                                    >
                                                        <ListIcon
                                                            as={FaCheck}
                                                            color={feature.color}
                                                        />
                                                        <Text fontSize="sm" color={textColor}>
                                                            {detail}
                                                        </Text>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </VStack>
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Technologies Section */}
            <Container maxW="8xl" py={20}>
                <VStack spacing={12}>
                    <VStack spacing={4} textAlign="center">
                        <Badge colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm">Technologies</Badge>
                        <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                            Notre Stack Technologique
                        </Heading>
                        <Text fontSize="lg" color={textColor} maxW="3xl">
                            Nous utilisons les technologies les plus récentes et performantes
                            pour développer vos applications.
                        </Text>
                    </VStack>

                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8} w="full">
                        {technologies.map((tech, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <Box
                                    bg={cardBg}
                                    p={6}
                                    borderRadius="xl"
                                    shadow="xl"
                                    height="full"
                                >
                                    <VStack spacing={4} align="start">
                                        <Heading as="h3" fontSize="xl" color={headingColor}>
                                            {tech.category}
                                        </Heading>
                                        <List spacing={2}>
                                            {tech.techs.map((t, idx) => (
                                                <ListItem
                                                    key={idx}
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    <ListIcon as={FaCode} color="orange.500" />
                                                    <Text color={textColor}>{t}</Text>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </VStack>
                                </Box>
                            </MotionBox>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>

            {/* Process Section */}
            <Box bg={cardBg} py={20}>
                <Container maxW="8xl">
                    <VStack spacing={12}>
                        <VStack spacing={4} textAlign="center">
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Notre Processus</Badge>
                            <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                                Comment nous développons votre application
                            </Heading>
                            <Text fontSize="lg" color={textColor} maxW="3xl">
                                Un processus éprouvé pour transformer votre idée en application performante
                            </Text>
                        </VStack>

                        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
                            {process.map((step, index) => (
                                <MotionBox
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.2 }}
                                >
                                    <Box
                                        bg={bg}
                                        p={8}
                                        borderRadius="xl"
                                        shadow="xl"
                                        position="relative"
                                        height="full"
                                        h={{ base: 'auto', lg: '310px', xl: '270px' }}

                                    >
                                        <VStack spacing={4}>
                                            <Box
                                                bg="purple.500"
                                                color="white"
                                                borderRadius="full"
                                                w={12}
                                                h={12}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text fontWeight="bold">{index + 1}</Text>
                                            </Box>
                                            <Heading as="h3" fontSize="xl" color={headingColor}>
                                                {step.title}
                                            </Heading>
                                            <Text color={textColor} textAlign="center">
                                                {step.description}
                                            </Text>
                                            <HStack color="orange.500"

                                                position={{ base: 'relative', lg: 'absolute', xl: 'absolute' }}
                                                bottom={{ base: '0', lg: '30px', xl: '30px' }}>
                                                <Icon as={FaClock} />
                                                <Text fontSize="sm" fontWeight="medium">
                                                    {step.duration}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                        {index < process.length - 1 && (
                                            <Box
                                                display={{ base: 'none', lg: 'block' }}
                                                position="absolute"
                                                right="-60%"
                                                top="50%"
                                                w="60%"
                                                h="2px"
                                                bg="purple.500"
                                                zIndex={0}
                                            />
                                        )}
                                    </Box>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>

            {/* Types d'Applications */}
            <Container maxW="8xl" py={20}>
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={16}>
                    {/* Types d'apps */}
                    <Box>
                        <VStack align="start" spacing={8}>
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Types d'Applications</Badge>
                            <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                                Solutions Adaptées à Vos Besoins
                            </Heading>
                            <SimpleGrid columns={1} spacing={8} w="full">
                                {appTypes.map((type, index) => (
                                    <Box
                                        key={index}
                                        bg={cardBg}
                                        p={6}
                                        borderRadius="xl"
                                        shadow="lg"
                                    >
                                        <Heading as="h3" fontSize="xl" mb={4} color={headingColor}>
                                            {type.type}
                                        </Heading>
                                        <SimpleGrid columns={2} spacing={3}>
                                            {type.examples.map((example, idx) => (
                                                <HStack key={idx}>
                                                    <Icon as={FaCheck} color="green.500" />
                                                    <Text fontSize="sm" color={textColor}>
                                                        {example}
                                                    </Text>
                                                </HStack>
                                            ))}
                                        </SimpleGrid>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        </VStack>
                    </Box>

                    {/* Garanties */}
                    <Box>
                        <VStack align="start" spacing={8}>
                            <Badge colorScheme="purple"
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="sm">Nos Garanties</Badge>
                            <Heading fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
                                Engagement Qualité
                            </Heading>
                            <List spacing={4}>
                                {guarantees.map((guarantee, index) => (
                                    <ListItem
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <ListIcon as={FaShieldAlt} color="orange.500" w={5} h={5} />
                                        <Text color={textColor} fontSize="lg">
                                            {guarantee}
                                        </Text>
                                    </ListItem>
                                ))}
                            </List>
                        </VStack>
                    </Box>
                </SimpleGrid>
            </Container>

            {/* CTA Final */}
            <Box bg={cardBg} py={20}>
                <Container maxW="3xl">
                    <VStack spacing={8} textAlign="center">
                        <Heading fontSize={{ base: '2xl', md: '4xl' }} color={headingColor}>
                            Prêt à Développer Votre Application ?
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            Transformez votre idée en réalité avec une équipe expérimentée
                            qui vous accompagne à chaque étape du développement.
                        </Text>
                        <Flex gap={4} direction={{ base: 'column', sm: 'row' }}>
                            <Button
                                as={RouterLink}
                                to="/Contact"
                                colorScheme="purple"
                                size="lg"
                                rightIcon={<FaRocket />}
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Démarrer Votre Projet
                            </Button>
                            <Button
                                as={RouterLink}
                                to="/Contact"
                                variant="outline"
                                colorScheme="purple"
                                size="lg"
                                _hover={{
                                    bg: 'white',            // Couleur de fond blanche au survol
                                    color: 'purple.600',     // Texte de couleur purple.600 au survol
                                    border: '1px solid',     // Ajoute une bordure
                                    borderColor: 'purple.600',
                                    transform: 'translateY(-2px)',
                                    shadow: 'xl' // Couleur de la bordure purple.600
                                }}
                            >
                                Nous Contacter
                            </Button>
                        </Flex>
                    </VStack>
                </Container>
            </Box>
        </Box>
    );
}