import React, { useState } from 'react';
import {
    Box,
    Link,
    Button,
    Container,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Stack,
    Flex,
    Text,
    VStack,
    HStack,
    Icon,
    SimpleGrid,
    Badge,
    InputGroup,
    InputLeftElement,
    Select,
    useToast,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    FaPhone,
    FaEnvelope,
    FaMapMarkerAlt,
    FaUser,
    FaComments,
    FaClock,
    FaCheckCircle,
    FaRocket
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';
import Logo from '../imagesComponents/RockYourNet.webp';

const MotionBox = motion(Box);

const contactMethods = [
    {
        icon: FaPhone,
        title: "Téléphone",
        value: "06 24 31 10 75",
        link: "tel:+33624311075",
        available: "Lun-Ven, 9h-18h"
    },
    {
        icon: FaEnvelope,
        title: "Email",
        value: "contact@rockyournet.com",
        link: "mailto:contact@rockyournet.com",
        available: "Réponse sous 24h"
    },
    {
        icon: FaMapMarkerAlt,
        title: "Localisation",
        value: "Auxerre, France",
        available: "Sur rendez-vous"
    }
];

const projectTypes = [
    "Site Vitrine",
    "Site E-commerce",
    "Application Web",
    "Référencement SEO",
    "Autre"
];

export default function ContactPage() {
    // État initial mis à jour avec les noms corrects
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        project: '', // Modifié pour correspondre au template
        budget: '',
        time: '',   // Modifié pour correspondre au template
        message: ''
    });

    const toast = useToast();

    // Couleurs et styles
    const bg = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.700');
    const headingColor = useColorModeValue('gray.800', 'white');
    const textColor = useColorModeValue('gray.600', 'gray.300');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    // Gestion des changements de formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    // Gestion de la soumission
    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('RockYourNet', 'RockYourNet', e.target, '59cn7LXxMiOG7vw2j')
            .then((result) => {
                toast({
                    title: 'Message envoyé avec succès !',
                    description: "Nous vous répondrons dans les plus brefs délais.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',
                });
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    project: '',
                    budget: '',
                    time: '',
                    message: ''
                });
            }, (error) => {
                toast({
                    title: 'Erreur lors de l\'envoi',
                    description: "Veuillez réessayer ou nous contacter directement.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',
                });
            });
    };

    return (
        <Box
            as="section"
            minH="100vh"
            bg={bg}
            py={{ base: '20', md: '32' }}
            backgroundImage={`url(${Logo})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundAttachment="fixed"
            position="relative"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: useColorModeValue(
                    'rgba(255, 255, 255, 0.95)',
                    'rgba(0, 0, 0, 0.9)'
                ),
                backdropFilter: 'blur(2px)',
                zIndex: 1,
            }}
        >
            <Container maxW="7xl" position="relative" zIndex={2}>
                <VStack spacing={16}>
                    {/* Header Section */}
                    <VStack spacing={6} textAlign="center" maxW="3xl" mx="auto">
                        <Badge
                            colorScheme="purple"
                            px={3}
                            py={1}
                            borderRadius="full"
                            fontSize="sm"
                        >
                            Contactez-nous
                        </Badge>
                        <Heading
                            as="h1"
                            fontSize={{ base: '3xl', md: '5xl' }}
                            fontWeight="bold"
                            color={headingColor}
                            lineHeight="shorter"
                        >
                            Parlons de Votre Projet
                        </Heading>
                        <Text
                            fontSize={{ base: 'lg', md: 'xl' }}
                            color={textColor}
                        >
                            Transformez votre vision en réalité avec notre expertise.
                            Prenez contact pour discuter de vos besoins et objectifs.
                        </Text>
                    </VStack>

                    {/* Contact Methods */}
                    <SimpleGrid
                        columns={{ base: 1, md: 3 }}
                        spacing={8}
                        w="full"
                    >
                        {contactMethods.map((method, index) => (
                            <MotionBox
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <VStack
                                    bg={cardBg}
                                    p={8}
                                    borderRadius="xl"
                                    shadow="xl"
                                    spacing={4}
                                    height="full"
                                    _hover={{
                                        transform: 'translateY(-5px)',
                                        shadow: '2xl',
                                    }}
                                    transition="all 0.3s"
                                >
                                    <Box
                                        bg="purple.500"
                                        p={3}
                                        borderRadius="full"
                                    >
                                        <Icon
                                            as={method.icon}
                                            w={6}
                                            h={6}
                                            color="white"
                                        />
                                    </Box>
                                    <Text
                                        fontWeight="bold"
                                        fontSize="xl"
                                        color={headingColor}
                                    >
                                        {method.title}
                                    </Text>
                                    {method.link ? (
                                        <Link
                                            href={method.link}
                                            color="purple.500"
                                            fontWeight="medium"
                                            fontSize="lg"
                                            _hover={{ textDecoration: 'none' }}
                                        >
                                            {method.value}
                                        </Link>
                                    ) : (
                                        <Text
                                            color={textColor}
                                            fontSize="lg"
                                        >
                                            {method.value}
                                        </Text>
                                    )}
                                    <Text
                                        fontSize="sm"
                                        color={textColor}
                                    >
                                        <Icon as={FaClock} mr={2} />
                                        {method.available}
                                    </Text>
                                </VStack>
                            </MotionBox>
                        ))}
                    </SimpleGrid>

                    {/* Contact Form Section */}
                    <Box
                        bg={cardBg}
                        borderRadius="2xl"
                        shadow="2xl"
                        overflow="hidden"
                        w="full"
                        maxW="4xl"
                        mx="auto"
                    >
                        <Box p={8}>
                            <VStack spacing={8} align="stretch">
                                <VStack spacing={4} align="start">
                                    <Heading
                                        as="h2"
                                        fontSize="2xl"
                                        color={headingColor}
                                    >
                                        Formulaire de Contact
                                    </Heading>
                                    <Text color={textColor}>
                                        Remplissez le formulaire ci-dessous pour nous parler de votre projet
                                    </Text>
                                </VStack>

                                <form onSubmit={handleSubmit}>
                                    <Stack spacing={6}>
                                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                                            <FormControl isRequired>
                                                <FormLabel>Nom complet</FormLabel>
                                                <InputGroup>
                                                    <InputLeftElement>
                                                        <Icon as={FaUser} color="gray.500" />
                                                    </InputLeftElement>
                                                    <Input
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        placeholder="Votre nom"
                                                    />
                                                </InputGroup>
                                            </FormControl>

                                            <FormControl isRequired>
                                                <FormLabel>Email</FormLabel>
                                                <InputGroup>
                                                    <InputLeftElement>
                                                        <Icon as={FaEnvelope} color="gray.500" />
                                                    </InputLeftElement>
                                                    <Input
                                                        name="email"
                                                        type="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        placeholder="votre@email.com"
                                                    />
                                                </InputGroup>
                                            </FormControl>
                                        </SimpleGrid>

                                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                                            <FormControl isRequired>
                                                <FormLabel>Téléphone</FormLabel>
                                                <InputGroup>
                                                    <InputLeftElement>
                                                        <Icon as={FaPhone} color="gray.500" />
                                                    </InputLeftElement>
                                                    <Input
                                                        name="phone"
                                                        type="tel"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        placeholder="Votre numéro"
                                                    />
                                                </InputGroup>
                                            </FormControl>

                                            <FormControl isRequired>
                                                <FormLabel>Type de projet</FormLabel>
                                                <Select
                                                    name="project"
                                                    value={formData.project}
                                                    onChange={handleChange}
                                                    placeholder="Sélectionnez le type"
                                                >
                                                    {projectTypes.map((type) => (
                                                        <option key={type} value={type}>
                                                            {type}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </SimpleGrid>

                                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                                            <FormControl>
                                                <FormLabel>Budget estimé</FormLabel>
                                                <Select
                                                    name="budget"
                                                    value={formData.budget}
                                                    onChange={handleChange}
                                                    placeholder="Sélectionnez une fourchette"
                                                >
                                                    <option value="< 2000€">Moins de 2000€</option>
                                                    <option value="2000€ - 5000€">2000€ - 5000€</option>
                                                    <option value="5000€ - 10000€">5000€ - 10000€</option>
                                                    <option value="> 10000€">Plus de 10000€</option>
                                                </Select>
                                            </FormControl>

                                            <FormControl>
                                                <FormLabel>Délai souhaité</FormLabel>
                                                <Select
                                                    name="time"
                                                    value={formData.time}
                                                    onChange={handleChange}
                                                    placeholder="Sélectionnez un délai"
                                                >
                                                    <option value="< 1 mois">Moins d'1 mois</option>
                                                    <option value="1-3 mois">1-3 mois</option>
                                                    <option value="3-6 mois">3-6 mois</option>
                                                    <option value="> 6 mois">Plus de 6 mois</option>
                                                </Select>
                                            </FormControl>
                                        </SimpleGrid>

                                        <FormControl isRequired>
                                            <FormLabel>Message</FormLabel>
                                            <Textarea
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                placeholder="Décrivez votre projet..."
                                                rows={6}
                                            />
                                        </FormControl>

                                        <Button
                                            type="submit"
                                            colorScheme="purple"
                                            size="lg"
                                            rightIcon={<FaRocket />}
                                            _hover={{
                                                transform: 'translateY(-2px)',
                                                shadow: 'lg',
                                            }}
                                        >
                                            Envoyer le message
                                        </Button>
                                    </Stack>
                                </form>
                            </VStack>
                        </Box>
                    </Box>
                </VStack>
            </Container>
        </Box>
    );
}