import React from 'react';
import {
    Box,
    Container,
    Text,
    VStack,
    HStack,
    Grid,
    GridItem,
    Link,
    Icon,
    Divider,
    useTheme,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
    FaLinkedin,
    FaGithub,
    FaPhone,
    FaEnvelope,
    FaMapMarkerAlt,
    FaCode,
    FaRocket,
    FaSearch
} from 'react-icons/fa';

const Footer = () => {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const borderColor = useColorModeValue('gray.200', 'gray.600');
    const linkHoverColor = useColorModeValue('purple.600', 'purple.300');
    const bg = useColorModeValue('white', 'purple.900');

    const services = [
        { name: "Sites Vitrines", icon: FaRocket, link: "/Services/Site-vitrine" },
        { name: "Sites E-commerce", icon: FaRocket, link: "/Services/Site-e-commerce" },
        { name: "Référencement SEO", icon: FaSearch, link: "/Services/Referencement" },
        { name: "Applications Web", icon: FaCode, link: "/Services/ApplicationWeb" }
    ];

    const links = [
        { name: "À Propos", link: "/A-propos" },
        { name: "Services", link: "/Services" },
        { name: "Contact", link: "/Contact" },
        { name: "Mentions Légales", link: "/Mentions-Légales" }
    ];

    const contacts = [
        { icon: FaPhone, content: "06 24 31 10 75", link: "tel:+33624311075" },
        { icon: FaEnvelope, content: "contact@rockyournet.com", link: "mailto:contact@rockyournet.com" },
        { icon: FaMapMarkerAlt, content: "Auxerre, France", link: "https://maps.app.goo.gl/SzCjBv2kuJK5KukG6" }
    ];

    const socials = [
        { icon: FaLinkedin, link: "https://www.linkedin.com/in/colaspinchon/" },
        { icon: FaGithub, link: "https://github.com/colas-pinchon" }
    ];

    return (
        <Box
            as="footer"
            pt={12}
            pb={6}
            bg={bg}
            color={colorMode === "dark" ? theme.colors.dark.bg : theme.colors.light.bg}
            borderTop="1px solid"
            borderColor={borderColor}
        >
            <Container maxW="8xl">
                <Grid
                    templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
                    gap={{ base: "4", lg: "8" }}
                    mb={10}
                >
                    {/* Entreprise */}
                    <GridItem>
                        <VStack align="start" spacing={4}>
                            <Text fontWeight="bold" fontSize="lg">Rock Your Net</Text>
                            <Text fontSize="sm" maxW="300px">
                                Spécialiste en création de sites web, applications et référencement SEO.
                                Notre mission est de propulser votre entreprise dans l'ère digitale.
                            </Text>
                            <HStack spacing={4}>
                                {socials.map((social, index) => (
                                    <Link
                                        key={index}
                                        href={social.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        _hover={{ color: linkHoverColor }}
                                    >
                                        {/*   <Icon as={social.icon} color="orange.500" w={5} h={5} />  */}
                                    </Link>
                                ))}
                            </HStack>
                        </VStack>
                    </GridItem>

                    <Divider display={{ base: "block", md: "none" }} borderColor={borderColor} />

                    {/* Services */}
                    <GridItem>
                        <VStack align="start" spacing={4}>
                            <Text fontWeight="bold" fontSize="lg">Nos Services</Text>
                            {services.map((service, index) => (
                                <Link
                                    key={index}
                                    as={RouterLink}
                                    to={service.link}
                                    _hover={{ color: linkHoverColor }}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Icon as={service.icon} color="orange.500" mr={2} />
                                    <Text fontSize="sm">{service.name}</Text>
                                </Link>
                            ))}
                        </VStack>
                    </GridItem>

                    <Divider display={{ base: "block", md: "none" }} borderColor={borderColor} />

                    {/* Liens */}
                    <GridItem>
                        <VStack align="start" spacing={4}>
                            <Text fontWeight="bold" fontSize="lg">Liens Rapides</Text>
                            {links.map((link, index) => (
                                <Link
                                    key={index}
                                    as={RouterLink}
                                    to={link.link}
                                    _hover={{ color: linkHoverColor }}
                                >
                                    <Text fontSize="sm">{link.name}</Text>
                                </Link>
                            ))}
                        </VStack>
                    </GridItem>

                    <Divider display={{ base: "block", md: "none" }} borderColor={borderColor} />

                    {/* Contact */}
                    <GridItem>
                        <VStack align="start" spacing={4}>
                            <Text fontWeight="bold" fontSize="lg">Contact</Text>
                            {contacts.map((contact, index) => (
                                <Link
                                    key={index}
                                    href={contact.link}
                                    _hover={{ color: linkHoverColor }}
                                    target='blank'
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Icon as={contact.icon} color="orange.500" mr={2} />
                                    <Text fontSize="sm">{contact.content}</Text>
                                </Link>
                            ))}
                        </VStack>
                    </GridItem>
                </Grid>

                <Divider borderColor={borderColor} mb={4} />

                <HStack
                    justifyContent="center"
                >
                    <Text fontSize="md" mt={2}>
                        © {new Date().getFullYear()} RockYourNet. Tous droits réservés.
                    </Text>
                </HStack>
            </Container>
        </Box>
    );
};

export default Footer;